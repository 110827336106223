import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  hashed:false,
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
    Segmented: {
      itemSelectedBg: "#021B38",
      itemSelectedColor: "#FFFFFF",
      trackPadding: 0,
      trackBg: "transparent",
    }
  },
}

export default theme
