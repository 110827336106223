import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { ConfigProvider } from "antd"
import { createContext, memo, useContext, useState, type FC, type PropsWithChildren } from "react"
import { RouterProvider } from "react-router-dom"
import { version } from "../../../package.json"
import introspection from "../../graphql"
import { router } from "../../pages"
import theme from "../../themes"

import { setContext } from "@apollo/client/link/context"
import { LOCALSTORAGE_ITEMS } from "../../utils/constants"
// import "./index.less"
const delay = () => import(`./index.less`)
setTimeout(() => delay(), 0)

interface ContextProps {
  app: { version: string }
  user: {
    id: string | null
    name: string | null
  }
  setUser: (user: { name: string | null, id: string | null }) => void,
  ipAddress: string | null,
  setIpAddress: (ipAddress: string | null) => void,
}

const userFromLs = JSON.parse(localStorage.getItem(LOCALSTORAGE_ITEMS.user) as string);
const ipAddressFromLs = localStorage.getItem(LOCALSTORAGE_ITEMS.ipAddress) as string;

const initialContext: ContextProps = {
  app: { version: version },
  user: {
    id: userFromLs?.id || null,
    name: userFromLs?.name || null,
  },
  setUser: user => { },
  ipAddress: ipAddressFromLs || null,
  setIpAddress: () => { }
}

const app: ContextProps["app"] = { version }

const Context = createContext(initialContext)

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const [user, setUser] = useState(initialContext.user)

  // The value provided to the context now includes the state and the updater function
  const contextValue = {
    ...initialContext,
    user,
    setUser,
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    if (networkError.message?.includes("401")) {
      localStorage.removeItem(LOCALSTORAGE_ITEMS.token)
      localStorage.removeItem(LOCALSTORAGE_ITEMS.user)
      window.location.href = "/login"
    }
  }
})

const httpLink = createHttpLink({
  uri: "/graphql",
})
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(LOCALSTORAGE_ITEMS.token)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider user={initialContext.user} setUser={initialContext.setUser} app={initialContext.app} ipAddress={initialContext.ipAddress} setIpAddress={initialContext.setIpAddress}>
      <ConfigProvider theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ContextProvider>
  </ApolloProvider>
))

export { useApp }

export default App
