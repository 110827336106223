import { Dropdown } from "antd"
import { ItemType } from "antd/es/menu/hooks/useItems"
import React, { FC, ReactNode } from "react"
// import "./CustomDropDown.less"
const delay = () => import(`./CustomDropDown.less`)
setTimeout(() => delay(), 0)

interface DropDownProps {
  items: ItemType[]
  children: ReactNode
}

export const CustomDropDown: FC<DropDownProps> = ({ children, items }) => {
  return (
    <Dropdown
      menu={{
        items: items,
      }}
    >
      {children}
    </Dropdown>
  )
}
