import { FC, lazy } from "react"
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom"

import { Layout } from "../components/layout/Layout"
import { AuthLayout } from "../components/layout/AuthLayout"

const Home = lazy<FC>(() => import("./home"))
const SellerPropertyPage = lazy<FC>(() => import("./seller-property"))
const BuyerPropertyPage = lazy<FC>(() => import("./buyer-property"))
const SellersPage = lazy<FC>(() => import("./sellers"))
const BuyersPage = lazy<FC>(() => import("./buyers"))
const SignUpPage = lazy<FC>(() => import("./auth/signup"))
const HowItWorksPage = lazy<FC>(() => import("./how-it-works"))
const HowItWorksSeller = lazy<FC>(() => import("./how-it-works/seller"))
const HowItWorksBuyer = lazy<FC>(() => import("./how-it-works/buyer"))
const HowItWorksPricing = lazy<FC>(() => import("./how-it-works/pricing"))
const HowItWorksAboutUs = lazy<FC>(() => import("./how-it-works/about-us"))
const RedirectPage = lazy<FC>(() => import("./redirect"))
const SigninPage = lazy<FC>(() => import("./auth/signin"))
const AccountSettings = lazy<FC>(() => import("./account-settings"))
const ResourcesPage = lazy<FC>(() => import("./resources"))
const ResourceByIdPage = lazy<FC>(() => import("./resource-by-id"))
const ContactUs = lazy<FC>(() => import("./contact-us"))
const ForgotPassword = lazy<FC>(() => import("./auth/forgot-password"))
const ResetPassword = lazy<FC>(() => import("./auth/reset-password"))
const TermsOfUse = lazy<FC>(() => import("./terms-of-use"))
const PrivacyPolicy = lazy<FC>(() => import("./privacy-policy"))
const ProjectExchange = lazy<FC>(() => import("./project-exchange"))
const FavouritesPage = lazy<FC>(() => import("./favourites"))
const DashboardPage = lazy<FC>(() => import("./dashboard"))

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={"/"} element={<Layout />}>
        <Route index={true} element={<Home />} />
        <Route element={<SellersPage />} path={"sellers"} />
        <Route element={<BuyersPage />} path={"buyers"} />
        <Route element={<SellerPropertyPage />} path={"seller/*"} />
        <Route element={<BuyerPropertyPage />} path={"buyer/*"} />
        <Route element={<HowItWorksPage />} path={"how-it-works"} />
        <Route element={<HowItWorksSeller />} path={"how-it-works/seller"} />
        <Route element={<HowItWorksBuyer />} path={"how-it-works/buyer"} />
        <Route element={<HowItWorksPricing />} path={"how-it-works/pricing"} />
        <Route element={<HowItWorksAboutUs />} path={"how-it-works/about-us"} />
        <Route element={<AccountSettings />} path={"account-settings"} />
        <Route element={<ResourcesPage />} path={"resources"} />
        <Route element={<ContactUs />} path={"contact-us"} />
        <Route element={<ResourceByIdPage />} path={"resources/:id"} />
        <Route element={<RedirectPage />} path={"connect/auth0/redirect"} />
        <Route element={<TermsOfUse />} path={"terms-of-use"} />
        <Route element={<PrivacyPolicy />} path={"privacy-policy"} />
        <Route element={<FavouritesPage />} path={"favourites"} />
        <Route element={<DashboardPage />} path={"dashboard"} />
        <Route element={<Outlet />} path={"*"} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route element={<SignUpPage />} path={"sign-up"} />
        <Route element={<SignUpPage />} path={"sign-up/third-stage/:id"} />
        <Route element={<SigninPage />} path={"login"} />
        <Route element={<ForgotPassword />} path={"forgot-password"} />
        <Route element={<ResetPassword />} path={'reset-password'} />
      </Route>
      <Route element={<ProjectExchange />} path={"project-exchange"} />
    </Route>
  )
)
