import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Loader, LoaderSizeTheme } from "../ui/Loader/Loader"

export const AuthLayout = () => {
  return (
    <Suspense fallback={<Loader size={LoaderSizeTheme.LARGE} minH={"100vh"} />}>
      <Outlet />
    </Suspense>
  )
}
