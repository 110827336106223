import BaseLayout from "antd/lib/layout"
import { FC, Suspense, memo } from "react"
import { Outlet } from "react-router-dom"
import { Header } from "./Header/Header"
import { Footer } from "./Footer/Footer"

// import "./index.less"
const delay = () => import(`./index.less`)
setTimeout(() => delay(), 0)

import { useLayoutQuery } from "../../graphql"
// import { useIpQuery } from "../../graphql"
import { Loader, LoaderSizeTheme } from "../ui/Loader/Loader"
import { LOCALSTORAGE_ITEMS } from "src/utils/constants"

const MemoHeader = memo(Header)
const MemoFooter = memo(Footer)
const MemoLoader = memo(Loader)

const Layout: FC = () => {
  const { data } = useLayoutQuery({ fetchPolicy: "cache-and-network" })
  const layout = data?.layout?.data?.attributes
  // const ipData = useIpQuery()
  localStorage.setItem(LOCALSTORAGE_ITEMS.ipAddress, "")

  return (
    <BaseLayout style={{ overflow: "hidden" }}>
      <BaseLayout.Header style={{ padding: 0 }}>
        <MemoHeader menu={layout?.headerMenu as LinkFragment[]} userMenu={layout?.userMenu as LinkFragment[]} />
      </BaseLayout.Header>
      <main>
        <Suspense fallback={<MemoLoader size={LoaderSizeTheme.LARGE} minH={"100vh"} />}>
          <Outlet />
        </Suspense>
      </main>
      <BaseLayout.Footer style={{ padding: 0, paddingTop: "85px" }}>
        <MemoFooter rights={layout?.rights} policies={layout?.policies as LinkFragment[]} />
      </BaseLayout.Footer>
    </BaseLayout>
  )
}

export { Layout }
