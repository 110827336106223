import { useNavigate } from "react-router"
import { useApp } from "../components/app"
import { useGetUserByIdQuery } from "../graphql"
import { useEffect, useState } from "react"
import { LOCALSTORAGE_ITEMS } from "../utils/constants"

export const useAuth = () => {
  const navigate = useNavigate()
  const { setUser, user } = useApp()
  const [currentUserId, setCurrentUserId] = useState(user?.id)
  const { data: userData } = currentUserId != null ? useGetUserByIdQuery({ variables: { id: currentUserId } }) : { data: { usersPermissionsUser: { data: null } } }
  // update user id
  useEffect(() => {
    if (user.id) {
      setCurrentUserId(user.id)
    }
  }, [user])

  useEffect(() => {
    if (userData && userData?.usersPermissionsUser?.data) {
      setUser({ id: userData.usersPermissionsUser?.data?.id as string, name: userData.usersPermissionsUser?.data?.attributes?.username as string })
    }
  }, [userData])
  const logout = async () => {
    localStorage.removeItem(LOCALSTORAGE_ITEMS.token)
    localStorage.removeItem(LOCALSTORAGE_ITEMS.user)
    setUser({ name: null, id: null })
    navigate("/")
    // window.location.reload()
  }

  const handleLogin = (jwt: string, user: { id: string, name: string }) => {
    localStorage.setItem(LOCALSTORAGE_ITEMS.token, jwt)
    localStorage.setItem(LOCALSTORAGE_ITEMS.user, JSON.stringify({ id: user.id, name: user.name }))
    localStorage.removeItem(LOCALSTORAGE_ITEMS.tempToken)
    // setUser({ id: user.id, name: user.name })
  }

  const getCurrentUser = () => {
    if (!user.name) {
      const userFromLs = JSON.parse(localStorage.getItem(LOCALSTORAGE_ITEMS.user) as string)
      if (userFromLs && userFromLs.name) {
        // setUser(userFromLs)
        return userFromLs
      }
    }

    return user
  }

  return {
    logout,
    handleLogin,
    getCurrentUser,
  }
}
