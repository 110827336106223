import { Avatar, Button, Col, Row } from "antd"
import React, { FC, Suspense, lazy, memo, useEffect, useState } from "react"
import { Link, NavLink } from "react-router-dom"

import { Logo } from "../../logo"
import { HeaderMenu } from "../../menu"

import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
// import "./Header.less"
const delay = () => import(`./Header.less`)
setTimeout(() => delay(), 0)

// import { MobileMenu } from "./components/drawer/MobileMenu"
// import { useApp } from "../../app"
// import { useLoginMutation } from "../../../graphql"
// import { useNavigate } from "react-router"
import { useAuth } from "../../../hooks/useAuth"
import { CustomDropDown } from "../../ui/DropDown/CustomDropDown"
import { LOCALSTORAGE_ITEMS } from "../../../utils/constants"
const MobileMenu = memo(lazy(() => import("./components/drawer/MobileMenu").then(module => ({ default: module.MobileMenu }))))

const Header: FC<{ menu: LinkFragment[]; userMenu: LinkFragment[] }> = ({ menu, userMenu }) => {


  const token = localStorage.getItem(LOCALSTORAGE_ITEMS.token)

  const screen = useBreakpoint()

  const { logout, getCurrentUser } = useAuth()
  const currentUser = getCurrentUser()

  const howItWorksItems = [
    {
      key: "1",
      label: <Link to={"how-it-works/seller"}>Seller</Link>,
    },
    {
      key: "2",
      label: <Link to={"how-it-works/buyer"}>Buyer</Link>,
    },
    {
      key: "3",
      label: <Link to={"how-it-works/pricing"}>Fee structure</Link>,
    },
    {
      key: "4",
      label: <Link to={"how-it-works/about-us"}>About us</Link>,
    },
  ]

  const items = [
    {
      key: "2",
      label: <a href={"/account-settings"}>Account Settings</a>,
    },
    {
      key: "1",
      label: <p onClick={logout}>Logout</p>,
    },

  ]

  const menuItems = menu?.map((link, index) => {
    if (index === 2) {
      return {
        key: link.url,
        label: <CustomDropDown
          items={howItWorksItems}
        >
          {/* <a onClick={(e) => e.preventDefault()}> */}
          {/* <Space> */}
          <NavLink to={link.url}>{link.title}</NavLink>
          {/*<DownOutlined />*/}
          {/* </Space> */}
          {/* </a> */}
        </CustomDropDown>,
      }
    }
    return { key: link.url, label: <NavLink to={link.url}>{link.title}</NavLink> }
  })

  return (
    <Row align={"middle"} justify={"space-between"} className={"header-wrapper container"}>
      <Col>
        <NavLink to={"/"} replace={true} aria-label="Homepage">
          <Logo />
        </NavLink>
      </Col>
      {screen.lg ? (
        <React.Fragment>
          {menuItems && (
            <Col className={"header-menu-wrapper"}>
              <HeaderMenu menuItems={menuItems} />
            </Col>
          )}
          {!token ? (
            <Col className={"header-user-menu-wrapper"}>
              {userMenu?.map(link => (
                <Button key={link.id} href={link.url}>
                  {link.title}
                </Button>
              ))}
            </Col>
          ) : (
            <CustomDropDown
              items={items}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Avatar
                  style={{
                    backgroundColor: "#f56a00",
                    verticalAlign: "middle",
                  }}
                  size={"large"}
                  gap={1}
                >
                  {currentUser?.name ? currentUser.name[0] : ''}
                </Avatar>
                {/*<DownOutlined />*/}
              </a>
            </CustomDropDown>
          )}
        </React.Fragment>
      ) : (
        <Suspense fallback={<div></div>}>
          <MobileMenu />
        </Suspense>
      )}
    </Row>
  )
}

export { Header }
