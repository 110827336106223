import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import App from "./components/app"

const rootElement = document.querySelector("#root") as HTMLElement

if (import.meta.env.NODE_ENV == "production") {
  // console.log = () => { };
  // console.debug = () => { }
  console.warn = () => { }
  console.error = () => { }
}

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
)
