import { MenuItemType } from "antd/es/menu/hooks/useItems"
import { FC } from "react"

import { useLocation } from "react-router-dom"

import Navigation from "./Navigation"

// import "./index.less"
const delay = () => import(`./index.less`)
setTimeout(() => delay(), 0)

export enum NavigationThemeMode {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

interface HeaderMenuProps {
  mode?: NavigationThemeMode
  menuItems: MenuItemType[]
  onClickFn?: VoidFunction
}

const HeaderMenu: FC<HeaderMenuProps> = ({ menuItems, mode = NavigationThemeMode.HORIZONTAL, onClickFn }) => {
  const { pathname } = useLocation()
  return (
    <Navigation
      mode={mode}
      onClick={() => {
        if (onClickFn) {
          onClickFn()
        }
      }}
      selectedKeys={pathname === "/" ? [""] : [pathname]}
      defaultActiveFirst={false}
      items={menuItems}
    />
  )
}
export { HeaderMenu }
