import { FC } from "react"

import LogoImage from "../../assets/icons/Logo.svg?react"

// import "./index.less"
const delay = () => import(`./index.less`)
setTimeout(() => delay(), 0)

export enum LogoTheme {
  WHITE = "white",
  BLACK = "black",
  BLUE = 'blue'
}

interface LogoProps {
  logoTheme?: LogoTheme
}
const Logo: FC<LogoProps> = ({ logoTheme = LogoTheme.WHITE }) => {
  return <LogoImage className={`logo-${logoTheme}`} />
}

export { Logo }
