import { Spin } from "antd"
import { FC } from "react"
// import "./Loader.less"
const delay = () => import(`./Loader.less`)
setTimeout(() => delay(), 0)

export enum LoaderSizeTheme {
  SMALL = "small",
  LARGE = "large",
}

interface LoaderProps {
  minH?: string
  size?: LoaderSizeTheme
}

export const Loader: FC<LoaderProps> = ({ minH = "1vh", size = LoaderSizeTheme.SMALL }) => {
  return (
    <div className={"loader-wrapper"} style={{ minHeight: minH }}>
      <Spin size={size} />
    </div>
  )
}
